<template>
  <div class="common">
    <div>
      <!-- 头部导航 -->
      <div class="common_nav" :class="logoImg ? '' :'bgtext'">
        <div class="floor">
          <div class="logo" style="cursor:pointer;" @click="handleToIndex">
            <img src="../assets/logo/logo.png" alt="" v-if="logoImg">
            <img src="../assets/logo/logox.png" alt="" v-else>
          </div>
            <div class="list">
                <ul class="navigation">
                  <li v-for="(item,index) of navlist" :key="index" :class="logoImg ? 'notext' :'Atext'" :style="{color: visibleTab == index ? '#fff' : ''}">
                    <a-dropdown placement="bottomCenter" :getPopupContainer="
                      triggerNode => {
                        return triggerNode.parentNode || document.body;
                      }
                    ">
                      <div class="text" :class="visibleTab == index ?'tabs':''" @click="handleClick(item.url,index,item.SNavigation)">{{item.FNavigation}}</div>
                      <a-menu slot="overlay" v-if="item.SNavigation.length != 0" style="border-top:2px solid #fbc914;">
                          <a-menu-item :class="secondIndex == index2 && visibleTab == index ? 'texts' : ''" v-for="(item2,index2) of item.SNavigation" :key="index2" @click="TabClick(item2.url,index,index2)">
                            {{item2.name}}
                          </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                  </li>
                </ul>
                <a-icon @click="handleSearch" type="search" :class="logoImg ? 'notext' :'Atext'" style="color:#fff;margin-bottom:15px;margin-left:15px;font-size:18px;cursor:pointer;" />
              </div>
        </div>
      </div>
      <div class="top_Img" v-if="showTopBanner">
        <img :src="pageImg" alt="">
        <div class="tips">{{pageTitle}}</div>
      </div>
      <!-- 分享 -->
      <!-- <div class="share">
        <span>扫描二维码分享</span>
        <div id="qrcode" class="shareImg"></div>
      </div> -->
      <!-- 中部内容展示 -->
      <div class="contents">
        <router-view />
      </div>
    </div>
    <!-- 底部导航 -->
    <div class="common_footer " style="margin-top:45px;background:#2e3033;color:#fff">
      <div style="display:flex;justify-content:space-between;max-width: 1400px;margin:0px auto;padding:30px 0px;">
        <div>
          <div style="padding-bottom:17px;font-weight:bold;font-size:18px;">联系我们</div>
          <div style="display:flex">
            <div style="margin-right:120px;">
              <div style="padding-bottom:17px;">邮箱：pm@gdpmaa.com</div>
              <div>地址：广东省广州市越秀区天河路45之21号</div>
            </div>
            <div style="margin-right:120px;">
               <div style="padding-bottom:17px;">邮政：510075</div>
              <!-- <div>传真：020-6668888</div> -->
            </div>
            <!-- <div style="margin-right:120px;">
               <div style="padding-bottom:17px;">地址：广州市珠江新城华利路61号</div>
            </div> -->
          </div>
        </div>
        <div style="display:flex;">
          <div style="margin:0px 20px;text-align:center;">
            <div style="width:100px;height:100px;margin-bottom:5px;border-radius:4px;"><img style="width:100%;height:100%;" src="../assets/account.png"></div>
            <div style="text-align:center;">微信公众号</div>
          </div>
          <!-- <div style="margin:0px 20px;text-align:center;">
            <div style="width:100px;height:100px;border:solid 1px red;margin-bottom:5px;border-radius:4px;"><img src=""></div>
            <div>关注微博</div>
          </div> -->
        </div>
        
      </div>
      <div style="background:#252629;">
        <div style="display:flex;max-width:1400px;height:67px;align-items:center;font-size:15px;color:#747577;margin:0 auto;">
          Copyright © 2020-2030 广东省精准医学应用学会
          <a href="https://beian.miit.gov.cn" target="_blank" style="margin-left:10px; color: rgb(116, 117, 119);">粤ICP备19109097号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import QRCode from "qrcodejs2";
export default {
  data(){
    return {
       visibleTab:0,
      secondIndex:0,
      navlist:[
        // {FNavigation:'首页',url:'home',SNavigation:[]},
        // {FNavigation:'新闻中心',url:'#',SNavigation:[
        //   {name:'联合体动态',url:'consortium'},
        //   // {name:'成员动态',url:'member'},
        //   // {name:'通知公告',url:'notice'},
        //   // {name:'前沿动态',url:'frontiertrends'},
        // ]},
        // {FNavigation:'项目活动',url:'#',SNavigation:[
        //   {name:'联合体项目A',url:'projectA'},
        //   // {name:'联合体项目B',url:'projectB'},
        //   // {name:'会议中心',url:'metting'},
        // ]},
        // {FNavigation:'科学普及',url:'#',SNavigation:[
        //   {name:'科普宣传',url:'sciencepropaganda'},
        //   {name:'科普人物',url:'sciencefigures'},
        // ]},
        // {FNavigation:'关于联合体',url:'#',SNavigation:[
        //   {name:'联合体简介',url:'brief'},
        //   {name:'章程制度',url:'system'},
        //   {name:'组织架构',url:'framework'},
        //   {name:'成员单位',url:'company'},
        //   {name:'资料下载',url:'download'},
        //   {name:'联系我们',url:'contact'},
        // ]},
      ],
      logoImg:true,
      showTopBanner:true,
      pageImg:'',
      pageTitle:'',
      searchMessage:{
        "page_index":1,
        "per_page_count":10,
      },
      // link:'',
    }
  },
  // components:{QRCode},
  watch:{
    $route(){
      this.visibleTab = localStorage.getItem('showIndex');
      this.secondIndex = localStorage.getItem('secondIndex');
      // let query = this.$route.query;
      // if(query.isTop){
      //   this.logoImg = false;
      // }
      // this.link = window.location.href;
      let url = this.$route.path;
      if(url == '/home' || url == '/searchpage'){
        this.showTopBanner = false;
      }else{
        this.showTopBanner = true;
      }
      // this.$nextTick(()=>{
      //   this.isShowOpen();
      //   this.qrcode();
      // })
    },
  },
  methods:{
    // 清除生成的二维码
    isShowOpen(topId) {
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
   },
    qrcode () {
        let that = this;
        let qrcode = new QRCode('qrcode', {
            width: 94,
            height: 94,        // 高度
            text:  that.link,   // 二维码内容
            // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            // background: '#f0f',   // 背景色
            // foreground: '#ff0'    // 前景色
        })
    },
    // logo跳转首页
    handleToIndex(){
      this.$router.push({path:'/home'});
      localStorage.setItem('showIndex','0');
      localStorage.setItem('secondIndex','-1');
    },
    TabClick(url,index,index2){
      let SpUrl = url.split('/');
      let query = {};
      query.id = SpUrl[1];
      if(SpUrl[0] == 'messageDetails'){
        query.isTop = 'Top';
        query.detailID = SpUrl[1];
        delete query.id;
      }
      this.$router.push({ path: SpUrl[0],query:query }); //路由
      this.visibleTab=index;
      this.secondIndex = index2;
      // window.localStorage.showNavi = index;
      localStorage.setItem('showIndex',index);
      localStorage.setItem('secondIndex',index2);
    },
    handleClick(url,index,length){
      if(length.length != 0){
        return false;
      }
      this.$router.push(url);
      this.visibleTab = index
      localStorage.setItem('showIndex',index);
    },
    handleScroll(){
      // let query = this.$route.query;
      // if(query.isTop){
      //   return false;
      // }
      var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
      if(top == 0){
        this.logoImg = true;
      }else{
        this.logoImg = false;
      }
    },
    handleSearch(){
      this.$router.push('search');
    },
    // 获取导航数据
    getNavlist(){
      let type = ['#','#','list','messageDetails','download','company','metting','list']
      this.$get('/v1/column/all').then(res=>{
        this.$root.$emit('searchdata',res);
        res.forEach((item,i)=>{
          let datajson = {FNavigation:'',url:'',SNavigation:[]}
          datajson.FNavigation = item.title;
          if(item.type == -1){
            datajson.url = 'home';
          }else{
            datajson.url = type[item.type];
          }
          if(item.child_column.length != 0){
            item.child_column.forEach((k,v)=>{
              let datajson2 = {name:'',url:''};
              datajson2.name = k.title;
              if(k.type == -1){
                datajson2.url = 'home'
              }else{
                datajson2.url = type[k.type] + '/' + k.id;
              }
              datajson.SNavigation.push(datajson2);
            })
          }
          this.navlist.push(datajson)
        })
      })
    },
    // 获取底部导航信息
    getFooterDetails(){

    },
    // 获取头部标题和图片
    getTopMessage(data){
      this.$post('/v1/news/column/' + data,this.searchMessage).then(res=>{
        this.pageImg = res.url;
        this.pageTitle = res.title;
      })
    }
  },
  mounted(){
    // this.link = window.location.href;
    this.visibleTab = localStorage.getItem('showIndex');
    this.secondIndex = localStorage.getItem('secondIndex');
    let url = this.$route.path;
    if(url == '/home' || url == '/searchpage'){
      this.visibleTab = 0;
      this.showTopBanner = false;
    }else{
      this.showTopBanner = true;
    }
    window.addEventListener("scroll",this.handleScroll);
    window.scrollTo(0,0);
    this.getNavlist();
    this.getFooterDetails();
    this.$nextTick(()=>{
      // 监听触发事件
      this.$root.$on('changeImg',res=>{
        this.getTopMessage(res);
      })
    });
    // this.$nextTick(()=>{
    //   this.qrcode();
    // })
  },
  updated(){
    let query = this.$route.query;
      // if(query.isTop){
      //   this.logoImg = false;
      // }else{
        var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
        if(top == 0){
          this.logoImg = true;
        }else{
          this.logoImg = false;
        }
      // }
  }
}
</script>

<style lang="scss" scoped>
.common{display: flex;flex-direction: column;justify-content:space-between;height:100vh;
  .share{position: fixed;top: 50%;right: 17px;transform: translateY(-50%);width: 36px;height: 188px;background: #46494f;z-index: 1000;border-radius: 2px;
  font-size: 14px;color: #fff;text-align: center;padding-top:20px;cursor: pointer;
  span{width: 14px;height:140px;display: inline-block;writing-mode:horizontal-tb;}
  .shareImg{position: absolute;left: -100px;top: 50%;transform: translateY(-50%);display:none;height: 94px;}
  }
  .share:hover{
    .shareImg{display: block;}
  }
  .common_nav{
    height: 72px;width:100%;position:fixed;z-index:20;left:0px;right:0px;
    .floor{
       max-width: 1400px;
        margin: 0 auto;height: 72px;width:100%;
    display: flex;justify-content: space-between;
    align-items: center;background: rgba(0,0,0,0);margin:auto;padding-right: 20px;
    }
  }
  .top_Img{position: relative;
      img{width: 100%;max-width: 100%;}
      .tips{position: absolute;top:50%;left:15%;font-size: 40px;font-weight: bold;color: #fff;max-width: 1400px;}
    }
}
.common_footer{
  width:100%;font-size: 14px;
}
.bgtext{background: #fff !important;border-bottom: 1px solid #d9d9d9 !important;}
.Atext{color:#666 !important;}
.notext{color: rgba(255,255,255,.8);}
</style>


<style lang="scss">
.ant-carousel .slick-dots{z-index: 20;}
.items .list:hover{
  .title1{color: #ffc800 !important;}
}
.items .list .title1{word-break:break-all;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;line-clamp: 1;-webkit-box-orient: vertical;}
.items .list .title2{word-break:break-all;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;height:36px !important;width:100%;max-width:100%;
  img{width: 100% !important;height: 100% !important;}
  // p{word-break:break-all;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;}
}
// .list{.Img{img{object-fit: contain;}}}
.title2{p{margin-bottom: 0 !important;}}
.noticeList .list:hover{
  .title1{color: #ffc800 !important;}
}
.ant-pagination-item-active{background: #2e3033 !important;border-color: #2e3033 !important;color: #fff !important;}
.ant-dropdown-menu{
  width:114px;
  text-align: center !important;
  margin:13px 0px 0px !important;
  padding:0;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
  padding:0px !important;
  width:114px !important;
  height:45px !important;
  line-height:45px !important;
  background:#fff;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: normal !important;
}
.ant-dropdown-menu-item:hover{background: #fbc91418 !important;border-radius: 4px !important;}
</style>

<style lang="scss" scoped>
.texts{color: #fbc914 !important;}
.list{
        display: flex;align-items: center;
        margin-top:16px;
        .navigation{
          text-align: center;
          display: flex;
          list-style: none;
          li{
            font-weight: bold;
            width:130px;
            // margin: 0 8px;
            height:35px;
            line-height: 35px;
            color:rgba(255,255,255,.8);
            font-size: 16px;
            .tabs{
              border-bottom: 2px solid #fbc914;
              // color: #fff;
            }
            .text{
              cursor: pointer;
              height:35px;
              // color: rgba(255,255,255,.8);
              display: inline-block;
            }
          }
        }
      }
</style>
