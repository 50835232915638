
import axios from 'axios';
import apiurl from './apiUrl.js'
// import router from '../router'


axios.defaults.timeout = 15000;
axios.defaults.baseURL ='';


//http request 拦截器
axios.interceptors.request.use(
  config => {
    const token = window.localStorage.token;
    config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type':'application/json',
      'Authorization':"Token "+token
    }
    let download = localStorage.getItem('download');
    // 文件下载处理
    if(download){
      config.responseType = 'arraybuffer';
    }
    // if(token){ 
    //   config.params = {'token':token}
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


//http response 拦截器
axios.interceptors.response.use(
  response => {
    // if(response.data.errCode ==2){
    //   router.push({
    //     path:"/login",
    //     querry:{redirect:router.currentRoute.fullPath}//从哪个页面跳转
    //   })
    // }
    if(response.data.code == 1001){
      // console.log(response.code)
      // router.push({
      //   path:"/login",
      // })
    }
    return response;
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * 封装get方法 登录微信
 * @param url
 * @param data
 * @returns {Promise}
 */

export function wxfetch(url,params={}){
  axios.defaults.timeout = 30000;
  return new Promise((resolve,reject) => {
    axios.get(url,{
      params:params
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      reject(err)
    })
  })
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url,params={}){
  return new Promise((resolve,reject) => {
    axios.get(apiurl+url,{
      params:params
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      reject(err)
    })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

 export function post(url,data = {}){
   return new Promise((resolve,reject) => {
     axios.post(apiurl+url,data)
          .then(response => {
            resolve(response.data);
          },err => {
            reject(err)
          })
   })
 }

 /**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.patch(apiurl+url,data)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

 /**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.put(apiurl+url,data)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}