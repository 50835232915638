<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  font-family: 'PingFang SC','Helvetica Neue','Microsoft YaHei UI','Microsoft YaHei','Noto Sans CJK SC',Sathu,EucrosiaUPC,Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  /* loading */
  .spin-loading{
  margin-top:10%;
  height:100%;
  }
  .spin-content {
    padding: 30px;
  }
}
</style>

<script>
export default {
  data(){
    return {}
  },
  methods: {
    // 添加判断方法
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  },
  mounted(){
    if(this._isMobile()) {
      // alert("http://union-vue-mobile.int.linkwho.cn/#" + window.location.href.split('#')[1]);
      window.location.href="http://m.gupm.net/#" + window.location.href.split('#')[1];
    }else {
      if(window.location.href.indexOf('192.168') > -1){
        return false;
      }
      // if(window.location.href.indexOf('union-vue.int') > -1){
      //   window.location.href="http://union-vue.int.linkwho.cn/"
      // }else{
      //   window.location.href="http://union.uat.linkwho.cn/"
      // }
    }
    // console.log("http://union-vue-mobile.int.linkwho.cn/#" + window.location.href.split('#')[1])
  }
}
</script>
 
