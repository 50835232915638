<template>
  <div class="search">
    <table></table>
    <div class="search_top">
      <div class="logo">
        <img src="../assets/logo/logox.png" alt="">
      </div>
      <img @click="cancel" style="width:35px;height:35px;cursor:pointer;" src="../assets/cancel.png" alt="">
    </div>
    <div class="search_name">
      <input v-model="searchname" type="text" placeholder="请输入关键词" @keyup.enter="Search" />
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      searchname:''
    }
  },
  methods:{
    Search(){
      if(!this.searchname){
        this.$message.error('请输入关键词搜索',.5);
        return false;
      }
      this.$router.push({path:'searchpage',query:{searchName:this.searchname}});
      localStorage.setItem('showIndex','-1');
      localStorage.setItem('secondIndex','-1');
    },
    cancel(){
      this.$router.go(-1);
    }
  },
  mounted(){

  }
}
</script>

<style lang="scss">
  .search{
    .search_top{display: flex;justify-content: space-between;max-width: 1400px;margin: 0 auto;align-items: center;margin-top: 160px;}
    .search_name{max-width: 1400px;margin: 0 auto;margin-top: 160px;
      input{border: none;outline: 0;width: calc(100% - 35px);font-size: 40px;border-bottom: 1px solid #8e8e8e;padding-bottom: 8px;margin-left:35px;}
    }
  }
</style>