import Vue from 'vue'
import VueRouter from 'vue-router'
import common from '../views/index.vue'
import search from '../components/searchtitle.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: common,
    redirect: '/home',
    children:[
      // 首页
      { 
        path:'/home',
        name:'Home',
        component: () => import('../views/home/home.vue')
      },
      // 联合体动态
      {
        path: '/list',
        name: 'NewsConsortium',
        component: () => import('../views/news/consortium.vue')
      },
      // 成员动态
      {
        path: '/member',
        name: 'Member',
        component: () => import('../views/news/member.vue')
      },
      // 通知公告
      {
        path: '/notice',
        name: 'Notice',
        component: () => import('../views/news/notice.vue')
      },
      // 前沿动态
      {
        path: '/frontiertrends',
        name: 'Frontiertrends',
        component: () => import('../views/news/frontiertrends.vue')
      },
      // 联合体项目A
      {
        path: '/projectA',
        name: 'ProjectA',
        component: () => import('../views/project/projectA.vue')
      },
      // 联合体项目B
      {
        path: '/projectB',
        name: 'ProjectB',
        component: () => import('../views/project/projectB.vue')
      },
      {
        path: '/messageDetails',
        name: 'MessageDetails',
        component: () => import('../views/news/messageDetails.vue')
      },
      // 会议中心
      {
        path: '/metting',
        name: 'Metting',
        component: () => import('../views/project/metting.vue')
      },
      // 科普宣传
      {
        path: '/sciencepropaganda',
        name: 'Sciencepropaganda',
        component: () => import('../views/science/sciencepropaganda.vue')
      },
      // 科普人物
      {
        path: '/sciencefigures',
        name: 'Sciencefigures',
        component: () => import('../views/science/sciencefigures.vue')
      },
      // 联合体简介
      {
        path: '/brief',
        name: 'Brief',
        component: () => import('../views/about/brief.vue')
      },
      // 章程制度
      {
        path: '/system',
        name: 'System',
        component: () => import('../views/about/system.vue')
      },
      // 组织架构
      {
        path: '/framework',
        name: 'Framework',
        component: () => import('../views/about/framework.vue')
      },
      // 成员单位
      {
        path: '/company',
        name: 'Company',
        component: () => import('../views/about/company.vue')
      },
      // 资料下载
      {
        path: '/download',
        name: 'Download',
        component: () => import('../views/about/download.vue')
      },
      // 联系我们
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/about/contact.vue')
      },
      {
        path: '/searchpage',
        name: 'Searchpage',
        component: () => import('../views/search/searchpage.vue')
      },
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: search,
  }
]

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {     
    if (savedPosition) {       
        return savedPosition     
    } else {       
        return { x: 0, y: 0 }     
    }   
  },
  routes,
  mode:'hash'
})

// 解决重复点击相同路由地址下报错
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default router
